var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("psi-stats-card", {
                attrs: {
                  to: { path: "/messages/view", query: { status: "sent" } },
                  "color-option": 1,
                  stat: _vm.stats.sent + " Sent",
                  title: "Sent Messages",
                  icon: "mdi mdi-message-arrow-right"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("psi-stats-card", {
                attrs: {
                  to: { path: "/messages/view", query: { status: "received" } },
                  "color-option": 2,
                  stat: _vm.stats.received + " Received",
                  title: "Received Messages",
                  icon: "mdi mdi-message-arrow-left"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("psi-stats-card", {
                attrs: {
                  to: { path: "/messages/view", query: { status: "error" } },
                  "color-option": 3,
                  stat: _vm.stats.error + " Failed",
                  title: "Failed Messages",
                  icon: "mdi mdi-message-alert"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("psi-stats-card", {
                attrs: {
                  to: {
                    path: "/messages/view",
                    query: { status: "delivered" }
                  },
                  "color-option": 4,
                  stat: _vm.stats.delivered + " Delivered",
                  title: "Delivered Messages",
                  icon: "mdi mdi-message-text-clock"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("psi-stats-card", {
                attrs: {
                  to: {
                    path: "/messages/view",
                    query: { status: "undelivered" }
                  },
                  "color-option": 1,
                  stat: _vm.stats.undelivered + " Undelivered",
                  title: "Undelivered Messages",
                  icon: "mdi mdi-message-bulleted-off"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("psi-stats-card", {
                attrs: {
                  to: { path: "/messages/view", query: { status: "queued" } },
                  "color-option": 2,
                  stat: _vm.stats.queued + " Queued",
                  title: "Queued Messages",
                  icon: "mdi mdi-message-processing"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }