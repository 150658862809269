<template>
    <div>
        <v-row fluid>
            <v-col cols="12" md="6" lg="4">
                <psi-stats-card
                    :to="{ path: '/messages/view', query: { status: 'sent'}}"
                    :color-option="1"
                    :stat="`${stats.sent} Sent`"
                    title="Sent Messages"
                    icon="mdi mdi-message-arrow-right"
                ></psi-stats-card>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <psi-stats-card
                    :to="{ path: '/messages/view', query: { status: 'received'}}"
                    :color-option="2"
                    :stat="`${stats.received} Received`"
                    title="Received Messages"
                    icon="mdi mdi-message-arrow-left"
                ></psi-stats-card>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <psi-stats-card
                    :to="{ path: '/messages/view', query: { status: 'error'}}"
                    :color-option="3"
                    :stat="`${stats.error} Failed`"
                    title="Failed Messages"
                    icon="mdi mdi-message-alert"
                ></psi-stats-card>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <psi-stats-card
                    :to="{ path: '/messages/view', query: { status: 'delivered'}}"
                    :color-option="4"
                    :stat="`${stats.delivered} Delivered`"
                    title="Delivered Messages"
                    icon="mdi mdi-message-text-clock"
                ></psi-stats-card>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <psi-stats-card
                    :to="{ path: '/messages/view', query: { status: 'undelivered'}}"
                    :color-option="1"
                    :stat="`${stats.undelivered} Undelivered`"
                    title="Undelivered Messages"
                    icon="mdi mdi-message-bulleted-off"
                ></psi-stats-card>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <psi-stats-card
                    :to="{ path: '/messages/view', query: { status: 'queued'}}"
                    :color-option="2"
                    :stat="`${stats.queued} Queued`"
                    title="Queued Messages"
                    icon="mdi mdi-message-processing"
                ></psi-stats-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "message-stats",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Message", ["stats"]),
    },
};
</script>

<style scoped>
</style>